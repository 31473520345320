<template>
  <div>
    <optionscard :deliverParentNum="6"></optionscard>
    <div class="bigpicture">
      <img :src="imageFour" class="bigPic" width="100%" height="100%" />
    </div>
    <div class="width1200">
      <!-- 左边内容 -->
      <div class="classifyDiv">
        <div class="classify-left">
          <div class="classify-left-top">分类</div>
          <!-- 分类名字 -->
          <li
            @click="switchClassify(item.id, 'Button_' + index)"
            :class="show == item.id ? 'blue2' : 'white2'"
            v-for="(item, index) in serveList"
            :key="item.id"
          >
            {{ item.title }}
          </li>
        </div>
      </div>
      <!-- 右边内容 -->
      <div class="rightDiv">
        <div v-for="(item, index) in serveList" :key="item.id">
          <div class="itemBody" :id="'Button_' + index" ref="pronbit">
            <div class="bluebulk"></div>
            <div class="bluebulkWord">{{ item.title }}</div>
          </div>
          <div v-if="item.image != ''"><img :src="item.image" /></div>
          <div class="rightImg" v-else><img /></div>
          <div class="rightInfo" v-html="item.info"></div>
        </div>
        <div>
          <!-- 地图 -->
          <div class="map">
            <div id="mapapp"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import optionscard from '@/components/optionscard.vue'
export default {
  data () {
    return {
      show: 1,
      // 存放数据
      serveList: {},
      // 存放广告图
      advertisingPic: [],
      // 广告图
      imageFour: '',
      conter: null, // 地图中心点
      map: null, // 地图
      markerLayer: null // MultiMarker
    }
  },
  components: {
    // 头部选项卡（首页 场馆展品 场馆活动 地震知识 参观服务
    optionscard
  },
  created () {
    this.getAdvertisingPic()
    this.getServe()
  },
  mounted () {
    this.initMap()
  },
  methods: {
    // show高亮
    getlist (id) {
      this.serveList[0].id = id
    },
    // 获取广告图
    async getAdvertisingPic () {
      const { res } = await this.$api.venueactivity.getAdvertisingPic(
        this.advertisingList
      )
      console.log('res123', res)
      this.imageFour = res.canguan[0].pic
    },
    // 获取参观服务数据
    async getServe () {
      const { res } = await this.$api.serve.getServe()
      console.log('res', res)
      this.serveList = res
      console.log(this.serveList)
      this.serveList = [...res, { title: '地图' }]
      this.getlist(1)
      const t = this.$route.query.t
      this.virtualOmnirange(t)
    },
    //
    Button (index) {
      return 'Button' + index
    },
    // 切换分类
    switchClassify (id, Button) {
      this.$router.push({ path: '/sixth/cgfxfirst' })
      this.show = id
      console.log('id', id)
      document.querySelector('#' + Button).scrollIntoView(true)
    },
    // 首页的虚拟导航
    virtualOmnirange (t) {
      const that = this
      that.$nextTick(function () {
        if (t === '' || t === undefined) {
          console.log(1)
        } else {
          this.show = this.serveList[this.serveList.length]
          that.$nextTick(function () {
            window.scrollTo(0, document.documentElement.scrollHeight)
            // this.appointment1()
          })
        }
      })
    },
    // 地图初始化函数，本例取名为init，开发者可根据实际情况定义
    initMap () {
      // 定义地图中心点坐标
      var center = new window.TMap.LatLng(23.38136, 116.72787)
      // 定义map变量，调用 TMap.Map() 构造函数创建地图
      var map = new window.TMap.Map(document.getElementById('mapapp'), {
        center: center, // 设置地图中心点坐标
        zoom: 17.2, // 设置地图缩放级别
        pitch: 43.5, // 设置俯仰角
        rotation: 45 // 设置地图旋转角度
      })
      console.log(map)
      console.log(window)
      // 创建并初始化MultiMarker
      var markerLayer = new window.TMap.MultiMarker({
        map: map, // 指定地图容器
        // 点标记数据数组
        geometries: [
          {
            id: '1', // 点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
            styleId: 'myStyle', // 指定样式id
            position: new window.TMap.LatLng(23.38136, 116.72787) // 点标记坐标位置
          }
        ]
      })
      // 初始化infoWindow
      var infoWindow = new window.TMap.InfoWindow({
        map: map,
        title: '汕头市地震局',
        position: new window.TMap.LatLng(23.38136, 116.72787),
        offset: { x: 0, y: -32 } // 设置信息窗相对position偏移像素，为了使其显示在Marker的上方
      })
      infoWindow.close() // 初始关闭信息窗关闭
      // 监听标注点击事件
      markerLayer.on('click', function (evt) {
        // 设置infoWindow
        infoWindow.open() // 打开信息窗
        infoWindow.setPosition(evt.geometry.position) // 设置信息窗位置
        // infoWindow.setContent(evt.geometry.position.toString())// 设置信息窗内容
        infoWindow.setContent(
          '<p>汕头市地震局</p>' + evt.geometry.position.toString()
        )
      })
      console.log(markerLayer)
    }
  }
}
</script>

<style lang="scss" scoped>
.bigpicture {
  width: 100%;
  object-fit: cover;
  height: 245px;
}

.bigPic {
  object-fit: cover;
}

.width1200 {
  display: flex;
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 30px;
}

.classifyDiv {
  width: 280px;
  margin-top: 60px;
  background-color: #f7f7f7;
  height: 78px;
}

.bluebulk {
  width: 4px;
  height: 24px;
  background-color: #2e88e4;
  display: inline-block;
  margin-top: 8px;
}

.introduce-title {
  display: flex;
  margin-top: 60px;
}

.rightDiv {
  width: 800px;
  margin-left: 45px;
}

.itemBody {
  display: flex;
  margin-top: 60px;
  margin-bottom: 39px;
}

.bluebulkWord,
.introduce-title-word {
  font-size: 28px;
  color: #333333;
  margin-left: 10px;
}

.introduce-picture {
  width: 800px;
  height: 301px;
}

.introduce-word {
  margin-top: 50px;
}

.classify-left {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}

.classify-left-top {
  margin-left: 30px;
  height: 78px;
  line-height: 78px;
  color: #606060;
  font-size: 24px;
}

.blue2 {
  font-size: 16px;
  padding-left: 30px;
  line-height: 66px;
  color: #fff;
  width: 250px;
  height: 66px;
  background-color: #2e88e4;
  list-style: none;
  cursor: pointer;
}

.white2 {
  font-size: 16px;
  padding-left: 30px;
  line-height: 66px;
  width: 250px;
  color: #606060;
  height: 66px;
  list-style: none;
  background-color: #f7f7f7;
  cursor: pointer;
}

.navigation13 {
  width: 280px;
  height: 70px;
  margin-top: 13px;
  background-color: #494949;
}

.navigation3 {
  margin-left: 25px;
  line-height: 70px;
  color: #fff;
  font-size: 20px;
}

.relation {
  margin-top: 30px;
  margin-left: 30px;
  width: 225px;
}

.rightImg{
  display: none
}

.rightInfo{
  font-size: 16px;
  white-space: pre-line;
}

.map {
  width: 641px;
  height: 400px;
  border: solid 1px #cccccc;
}

#mapapp {
  width: 100%;
  height: 100%;
}

.map-amplify {
  width: 641px;
  color: #2e88e4;
  text-align: center;
  line-height: 34px;
  border: solid 1px #cccccc;
  cursor: pointer;
}
</style>
